// https://www.zebra.com/us/en/support-downloads/knowledge-articles/evm/What-is-AIM-identifier-for-Barcode.html

import { useCallback, useRef } from 'react'
import { MAudio } from '@mprise/react-ui/dist/audio'
import BarCodeInputClassic from '@mprise/react-ui/dist/controls/barcode/classic'
import { useAppSettingsContext, ScanningSetting } from '../context/AppSettingsContext'

function selectAllTextOfInput(input: Partial<HTMLInputElement>) {
  input.setSelectionRange?.(0, input.value?.length ?? 0)
}

export const useBarcodeInput = (name: string, nextName: string = name) => {
  const input = useRef<HTMLInputElement>(null)

  const focus = useCallback((select = true) => {
    if (input.current?.autofocus && select) {
      selectAllTextOfInput(input.current)
    }

    const inputFocused = window.document.activeElement === input.current
    if (input.current && !inputFocused) {
      input.current.focus?.()
    }
  }, [])

  const next = useCallback(
    (altNextName: string = nextName) => {
      const next = input.current?.form?.[altNextName] as Partial<HTMLInputElement> | null
      const nextFocused = window.document.activeElement === next
      if (next && !nextFocused) {
        selectAllTextOfInput(next)
        next.focus?.()
      }
    },
    [nextName],
  )

  const { scanningSetting } = useAppSettingsContext()
  const onBadInput = useCallback((prevValue: string) => {
    // Feedback
    MAudio.scanError()
    const hasChanged = input.current?.value !== prevValue

    const isKeyboard = scanningSetting === ScanningSetting.KEYBOARD
    // do not select text on bad input when using the keyboard for input, allow users
    // to type more slowly.
    const shouldSelect = !hasChanged && !isKeyboard
    // we do not focus on error, because it will mess with the flow of the user
    // focus(shouldSelect)
  }, [])

  const onGoodInput = useCallback(
    (prevValue: string, altNextName: string = nextName) => {
      MAudio.scanSuccess()
      const hasChanged = input.current?.value !== prevValue
      const shouldSelect = !hasChanged
      const inputFocused = window.document.activeElement === input.current
      const bodyFocused = window.document.activeElement === document.body /* when input disabled */
      const shouldFocusNext = inputFocused || bodyFocused
      if (shouldFocusNext) {
        next(altNextName)
      }
    },
    [next],
  )

  return {
    name,
    focus,
    next,
    input,
    onBadInput,
    onGoodInput,
  }
}

export interface BarcodeInputProps {
  api: ReturnType<typeof useBarcodeInput>
  autoFocus?: boolean
  disabled?: boolean
  text: string
  openCameraOnFocus?: boolean
  onSubmit?: (text: string) => void
  onChange: (text: string) => void
}

export const BarcodeInput = ({
  api,
  autoFocus,
  disabled,
  text,
  onSubmit,
  onChange,
  openCameraOnFocus = true,
}: BarcodeInputProps) => {
  const { scanningSetting } = useAppSettingsContext()

  return (
    <BarCodeInputClassic
      className='animated-blink'
      disabled={disabled}
      scanningSetting={scanningSetting}
      ref={api.input}
      name={api.name}
      value={text}
      onChange={onChange}
      onSubmit={onSubmit}
      status={0}
      autoFocus={autoFocus}
      openCameraOnFocus={openCameraOnFocus}
      tabIndex={null as any}
      focusAfterKeyboardSwitch={false}
      showSwitchKeyboardOption={true}
    />
  )
}
