import { useLazyQuery, useMutation } from '@apollo/client'
import { MAudio } from '@mprise/react-ui'
import { CSSProperties, useEffect, useState } from 'react'
import { useHistory } from '../../shared/use-history'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { ListItem } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { GET_STATUSES } from '../../gql/statuses'
import { ACTIVATE } from '../../gql/activate'
import { fail } from '../../shared/typescript'
import { ActivateAction, ActivateItem, ActivateReducer, ActivateSelectors, ActivateStatus } from './reducer'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { parseError, parseErrorResponse } from '../../shared/errors'
import { TranslationKey } from '../../shared/translation'
import { FixedSizeList as VList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

export const ActivatePerTrackingRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { defaultResource, currentCompany, defaultActivateStatus, setDefaultActivateStatus } = useAppSettingsContext()

  const [text, setText] = useState<string>('')
  const [statuses, setStatuses] = useState<any[]>([])
  const [state, dispatch] = ActivateReducer.useReducer()
  const alerts = FlashAlerts.useAlert()
  const trackingIdCode = useBarcodeInput('trackingIdCode')

  const dispatchWithAlerts = (action: ActivateAction) => {
    dispatch(action)

    if (action.type === 'success') {
      MAudio.scanSuccess()
      alerts.push(t(action.statusTextKey), action.type)
    } else if (action.type === 'error') {
      MAudio.scanError()
      alerts.push(t(action.messageTextKey, action.messageArgs), action.type)
    }
  }

  const [activate] = useMutation(ACTIVATE)

  const [getStatuses, { data: statusData }] = useLazyQuery(GET_STATUSES, {
    variables: {
      companyId: +currentCompany!.id,
      statuses: ['ACTIVE'],
    },
    onError: () => {
      alerts.push(t('NOTIFICATION_ERROR_LOADING_STATUSES'), 'error')
    },
  })

  useEffect(() => {
    if (currentCompany && currentCompany.id) {
      getStatuses({
        variables: {
          companyId: +currentCompany.id,
          statuses: ['ACTIVE'],
        },
      })
    }
  }, [currentCompany, getStatuses])

  useEffect(() => {
    if (statusData && statusData.customStatuses) {
      setStatuses(statusData.customStatuses)
    }
  }, [statusData])

  async function tryActivateTrid(trackingIdCode: string) {
    const resourceId = defaultResource?.id ?? fail('expects resource id')
    const statusId = defaultActivateStatus?.id

    if (!statusId) {
      alerts.push(t('NOTIFICATION_STATUS_REQUIRED'), 'error')
      MAudio.scanError()
      return
    }

    dispatchWithAlerts({
      type: 'add',
      text: trackingIdCode,
      statusTextKey: 'ACTIVATE_PROGRESS_VALIDATING',
    })

    activate({
      variables: {
        companyId: +currentCompany!.id,
        trackingCode: trackingIdCode,
        statusId: +statusId,
        resourceId: +resourceId,
      },
    })
      .then(value => {
        const response = value?.data?.activate
        if (response?.success) {
          dispatchWithAlerts({
            type: 'success',
            text: response.trackingId?.code,
            statusTextKey: response.errorMessage ?? 'ACTIVATE_PROGRESS_SAVED',
          })
        } else {
          const { errorMessage, messageArgs } = parseErrorResponse(response)
          dispatchWithAlerts({
            type: 'error',
            text: text,
            statusTextKey: 'ACTIVATE_PROGRESS_VALIDATIONERROR',
            messageTextKey: errorMessage,
            messageArgs: messageArgs,
          })
        }
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)
        dispatchWithAlerts({
          type: 'error',
          text: text,
          statusTextKey: 'UNEXPECTED_ERROR',
          messageTextKey: errorMessage as TranslationKey,
          messageArgs: messageArgs,
        })
      })
  }

  // Handlers
  const handleReset = () => {
    dispatch({ type: `reset` })
    setText(``)
    setTimeout(() => {
      h.push(`/`)
      h.push(`/activatePerTracking`)
    }, 0)
  }

  const handleCancel = () => {
    h.push('/')
  }

  const handleChangeStatus = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStatusId = evt.target.value

    const status = statuses?.find((x: any) => x.id === selectedStatusId)
    if (status) {
      setDefaultActivateStatus({
        id: status.id,
        name: status.name,
      })
    }
  }

  const addPendingTrackingId = (trackingIdCode: string) => {
    if (!trackingIdCode || ActivateSelectors.isPending(state, trackingIdCode)) {
      return
    }

    if (ActivateSelectors.exists(state, trackingIdCode)) {
      MAudio.scanError()
      alerts.push(t('NOTIFICATION_ALREADY_SCANNED'), 'error')
      return
    }

    tryActivateTrid(trackingIdCode)
  }

  if (!defaultResource) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_ACTIVATE')} />
  }

  return (
    <>
      <div className='box'>
        <PageHeader title={t('TITLE_ACTIVATE')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <Card>
            <Form>
              <Field label={t('FIELD_TRACKING_ID_CODE')}>
                <BarcodeInput
                  api={trackingIdCode}
                  autoFocus
                  text={text}
                  onChange={setText}
                  onSubmit={addPendingTrackingId}
                />
              </Field>
              <Field label={t('FIELD_STATUS')} required filled={defaultActivateStatus !== null}>
                <select className='ml-input' value={defaultActivateStatus?.id ?? `-`} onChange={handleChangeStatus}>
                  <option value={`-`}>{t('PLACEHOLDER_NOT_SET')}</option>
                  {statuses &&
                    statuses.map((x: any) => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                </select>
              </Field>
            </Form>
          </Card>
        </Section>
        <ActivateRouteValidation items={ActivateSelectors.validating(state)} />
        <ActivateRouteHistory items={ActivateSelectors.history(state)} />
      </div>
    </>
  )
}

const ActivateRouteTrackingListItem = ({ item, style }: { item: ActivateItem; style?: CSSProperties }) => {
  const { t } = useTranslation()

  const statusMap: Record<ActivateStatus, StatusValue> = {
    error: `bad`,
    pending: `neutral`,
    success: `good`,
  }
  const status = statusMap[item.status]

  return (
    <ListItem
      style={style}
      primary={
        <Flex gap='1rem'>
          <div style={{ flexGrow: 1 }}>
            <StatusText status={status}>{item.text}</StatusText>
          </div>
          <StatusText status={status}>{t(item.statusTextKey, item.messageArgs)}</StatusText>
        </Flex>
      }
      secondary={
        item.showMessageInHistory !== false && item.messageTextKey ? t(item.messageTextKey, item.messageArgs) : null
      }
    />
  )
}

const ActivateRouteValidation = ({ items }: { items: ActivateItem[] }) => {
  const { t } = useTranslation()

  return (
    <Section>
      <Card header={<Counter count={items.length}>{t('TITLE_VALIDATION')}</Counter>}>
        <ListItem primary={t(`NOTIFICATION_SCAN_TRACKING_ID`)} />
        {items.map(x => (
          <ActivateRouteTrackingListItem item={x} key={x.text} />
        ))}
      </Card>
    </Section>
  )
}

const ActivateRouteHistory = ({ items }: { items: ActivateItem[] }) => {
  const { t } = useTranslation()

  if (items.length === 0) {
    return null
  }

  const count = items.reduce(
    (acc, item) => {
      if (item.status === 'error') {
        acc.fail++
        return acc
      }
      acc.success++
      return acc
    },
    { success: 0, fail: 0 },
  )

  return (
    <Section style={{ flex: '1 1 auto' }}>
      <Card
        style={{ height: '95%' }}
        header={
          <Counter countSuccess={count.success} countFail={count.fail}>
            {t('TITLE_HISTORY')}
          </Counter>
        }
      >
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            const data = items.slice().reverse().slice(0, 49)
            return (
              <VList
                itemData={data}
                innerElementType='ul'
                itemCount={data.length || 0}
                itemSize={55}
                height={height}
                width={width}
                className='ml-list'
              >
                {({ data: x, index: i, style }) => (
                  <ActivateRouteTrackingListItem style={style} item={x[i]!} key={x[i]!.text} />
                )}
              </VList>
            )
          }}
        </AutoSizer>
      </Card>
    </Section>
  )
}
