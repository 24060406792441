import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { not, fail } from '../../shared/typescript'
import styled from '@emotion/styled'
import { MText } from '@mprise/react-ui'
import { MAudio } from '@mprise/react-ui/dist/audio'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { i18n } from '../../i18n/instance'
import { BlockingMessage } from '../../mprise-light/blocking-message'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { useAsync } from '../../shared/use-async'
import { useHistory } from '../../shared/use-history'
import { LoadOutputDetails } from './output-details'
import { LoadReceipt } from './receipt'
import { LoadAccepted, LoadPending, LoadReducer, LoadSelectors, LoadSpec, LoadState, Selectors } from './reducer'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { LOAD_INPUT, LOAD_OUTPUT, UNLOCK_CARRIER } from '../../gql/load'
import { parseError } from '../../shared/errors'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { ClearFieldButton } from '../../shared/clear-field-button'

export const LoadRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id!

  const [state, dispatch] = LoadReducer.useReducer()

  const outputTrackingId = useBarcodeInput(`outputTrackingId`, `inputTrackingId`)
  const inputTrackingId = useBarcodeInput(`inputTrackingId`)

  const [inputScanText, setInputScanText] = useState(``)
  const [outputPending, setOutputPending] = useState({ text: `` })

  const [inputSpace, setInputSpace] = useState<LoadSpec>()

  const [getTracking, { error: trackingError, data: trackingData }] = useLazyQuery(GET_TRACKING_ID)
  const [loadInputScan, { data: loadInputData }] = useMutation(LOAD_INPUT)
  const [loadOutputScan, { data: loadOutputData }] = useMutation(LOAD_OUTPUT)
  const [unlockCarrier] = useMutation(UNLOCK_CARRIER)

  const handleCancel = () => {
    const carrierId = (state.outputAccepted && state.outputAccepted.carrierId) || null
    if (carrierId) {
      unlockCarrier({
        variables: {
          carrierId: carrierId,
        },
      })
    }

    dispatch({ type: `reset` })
    setTimeout(() => h.push(`/`), 0)
  }

  const handleHome = () => {
    dispatch({ type: `view-home` })
  }

  const handleReset = () => {
    const carrierId = (state.outputAccepted && state.outputAccepted.carrierId) || null

    if (carrierId) {
      unlockCarrier({
        variables: {
          carrierId: carrierId,
        },
      })
    }

    dispatch({ type: `reset` })
    outputTrackingId.focus()
  }

  const alerts = FlashAlerts.useAlert()

  const { defaultResource } = useAppSettingsContext()

  const [firstPendingTrackingId] = state.inputPending

  useAsync(
    async abort => {
      if (abort.aborted) {
        return
      }

      if (firstPendingTrackingId && hasUnfulfilled) {
        const currentResourceId = defaultResource?.id ?? fail(`requires current resource to be set`)

        const { text, variantCode, quantity, itemId, warehouseTrackingCode, status } = firstPendingTrackingId

        const outputAccepted = state.outputAccepted ?? fail(`requires accepted output`)
        const checkIfEqualToInput = (x: LoadPending | LoadAccepted) => x.text === text
        const checkIfUnequalToInput = (x: LoadPending | LoadAccepted) => x.text !== text
        const isAlreadyPending = state.inputPending.filter(checkIfEqualToInput).length > 1
        const isAlreadyScanned = state.inputAccepted.some(checkIfEqualToInput)

        try {
          if (status && status !== 'AVAILABLE') {
            const message = 'NOTIFICATION_WRONG_STATUS_TRACKING_ID'
            dispatch({
              type: `rejected-input`,
              text,
              reason: message,
              reasonArgs: { status: t(`TRACKING_STATUS.${status}`) },
            })
            alerts.push(t(message, { status: t(`TRACKING_STATUS.${status}`) }), 'error')
            MAudio.scanError()
            return
          }

          if (isAlreadyScanned || isAlreadyPending) {
            const message = 'NOTIFICATION_ALREADY_SCANNED'
            dispatch({
              type: `rejected-input`,
              text,
              reason: message,
            })
            alerts.push(t(message), 'error')
            MAudio.scanError()
            return
          }

          // get current scanned amount for this tid
          // const { itemId, variantCode, quantity, warehouseTrackingCode  } = trackingId!
          const matchingSpecs = state.inputSpecs.filter(
            Selectors.MatchSpecsByItem(+itemId!, variantCode ?? null, warehouseTrackingCode!),
          )

          // do we still have space? Take into account pending scans.
          const space = matchingSpecs.find(s => {
            const otherPendingInputs = state.inputPending.filter(checkIfUnequalToInput)
            const used = [...state.inputAccepted, ...otherPendingInputs].filter(
              Selectors.MatchPendingSpecsByItem(itemId!, variantCode ?? null, warehouseTrackingCode!),
            )
            const usedQuantity = used.reduce((acc, n) => acc + n.quantity!, 0)
            const reserveQuantity = usedQuantity + quantity!
            return reserveQuantity <= s.quantity
          })

          if (matchingSpecs.length === 0) {
            const message = 'NOTIFICATION_WRONG_ITEM_OR_VARIANT_OR_WHTCODE'
            dispatch({
              type: `rejected-input`,
              text: text,
              reason: message,
            })
            alerts.push(t(message), 'error')
            MAudio.scanError()
            return
          }

          // if space continue.
          if (!space) {
            // get quantity we have.
            const maxQuantity = state.inputSpecs.find(
              Selectors.MatchSpecsByItem(+itemId!, variantCode ?? null, warehouseTrackingCode!),
            )?.quantity
            const message = 'NOTIFICATION_TOO_MANY'
            dispatch({
              type: `rejected-input`,
              text: text,
              reason: message,
              reasonArgs: { count: `${maxQuantity}` },
            })
            alerts.push(t(message, { count: maxQuantity }), 'error')
            MAudio.scanError()
            return
          }

          setInputSpace(space)

          loadInputScan({
            variables: {
              input: {
                carrierIdId: outputAccepted.carrierId,
                companyId: +companyId,
                resourceId: +currentResourceId,
                trackingIdCode: text,
                workItemId: outputAccepted.workItemId,
              },
            },
          }).catch(e => {
            dispatch({
              type: `rejected-input`,
              text: firstPendingTrackingId.text,
              reason: e.message,
            })
            alerts.push(e.message, `error`)
            MAudio.scanError()
          })
        } catch (ex) {
          const message = (ex as any).message
          dispatch({
            type: `rejected-input`,
            text: text,
            reason: 'NOTIFICATION_ERROR_X',
            reasonArgs: { x: message },
          })
          alerts.push(t(message, { x: message }), 'error')
          MAudio.scanError()
        }
      }
    },
    [firstPendingTrackingId],
  )

  useEffect(() => {
    if (loadOutputData && outputPending) {
      const result = loadOutputData.loadOutputScan
      const inputSpecs = result?.inputSpecs! as LoadSpec[]
      const inputAccepted = result?.inputAccepted! as LoadAccepted[]
      const outputText = outputPending!.text

      const dedupedInputAccepted = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(inputAccepted)
      const dedupedInputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(inputSpecs)
      const isFulfilled = dedupedInputSpecs.every(Selectors.isFulfilled(dedupedInputAccepted))

      // Change already loaded CarrierID to rejected-output and give error notification
      if (isFulfilled) {
        outputTrackingId.onBadInput(outputText)
        alerts.push(t('NOTIFICATION_CARRIER_ALREADY_LOADED'), 'error')

        dispatch({
          text: outputText,
          type: 'rejected-output',
          reason: t('NOTIFICATION_CARRIER_ALREADY_LOADED'),
        })
      } else {
        outputTrackingId.onGoodInput(outputText)

        dispatch({
          type: `accepted-output`,
          inputSpecs: result?.inputSpecs! as LoadSpec[],
          inputAccepted: result?.inputAccepted! as LoadAccepted[],
          text: outputText,
          carrierId: result?.carrierId as string,
          workItemId: result?.workItemId as string,
        })

        alerts.push(t(`NOTIFACTION_OUTPUT_ACCEPTED`), `success`)
      }
    }
  }, [loadOutputData])

  const handleBlockingErrorClick = () => {
    if (state.outputAccepted?.text) {
      dispatch({ type: 'change-input', text: '' })
      inputTrackingId.focus()
    } else {
      dispatch({ type: 'change-output', text: '' })
      outputTrackingId.focus()
    }
    dispatch({ type: 'reset-blocking-error' })
  }

  const inputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(state.inputSpecs)
  const inputAccepted = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(state.inputAccepted)

  const hasUnfulfilled = inputSpecs.some(not(Selectors.isFulfilled(inputAccepted)))

  useEffect(() => {
    if (loadInputData && firstPendingTrackingId) {
      const { text } = firstPendingTrackingId
      const space = inputSpace

      dispatch({
        text,
        type: `accepted-input`,
        ...(loadInputData.loadInputScan as Omit<LoadAccepted, 'text'>),
        quantityUnit: space!.quantityUnit,
        warehouseTrackingCode: space!.warehouseTrackingCode, // we can also change the result to provide the whtcode, but this is convenient.
        positionId: space!.positionId,
      })

      MAudio.scanSuccess()
      alerts.push(t(`NOTIFACTION_OUTPUT_ACCEPTED`), `success`)
    }
  }, [loadInputData])

  useEffect(() => {
    if (trackingData) {
      const { item, quantity, variantCode, warehouseTrackingCode, status } = trackingData.trackingId
      dispatch({
        type: 'admit-input',
        itemId: item?.id!,
        quantity: quantity ?? 0,
        text: inputScanText,
        variantCode: variantCode ?? '',
        warehouseTrackingCode: warehouseTrackingCode ?? null,
        status: status,
      })
    }
  }, [trackingData])

  useEffect(() => {
    if (trackingError) {
      const message = 'NOTIFICATION_NOT_FOUND'
      dispatch({
        type: `rejected-input`,
        text: inputScanText,
        reason: message,
      })
      alerts.push(i18n.t(message), 'error')
      MAudio.scanError()
    }
  }, [trackingError])

  const handleOutputAction = async (outputText: string) => {
    try {
      if (outputText) {
        const currentResourceId = defaultResource?.id ?? fail(`requires current resource to be set`)

        await loadOutputScan({
          variables: {
            input: {
              companyId: +companyId,
              carrierIdCode: outputText,
              resourceId: +currentResourceId,
            },
          },
        })
        setOutputPending({ text: outputText })
      }
    } catch (e: any) {
      const { errorMessage, messageArgs } = parseError(e)

      alerts.push(t(errorMessage, messageArgs), 'error')
      MAudio.scanError()

      dispatch({ type: 'change-output', text: '' })
      outputTrackingId.focus()
    }
  }

  const handleInputAction = async (inputText: string) => {
    try {
      if (inputText) {
        setInputScanText(inputText)
        // fetch trackingId for validation

        await getTracking({
          variables: {
            filter: {
              companyId: +companyId,
              code: inputText,
            },
          },
          fetchPolicy: 'no-cache',
        })
      }
    } catch (ex) {
      MAudio.scanError()
      alerts.push(Object(ex).message ?? String(ex), `error`)
    }
  }

  if (!defaultResource?.id) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_LOAD')} />
  }

  if (state.view === `details`) {
    return (
      <LoadReducer.Provider dispatch={dispatch}>
        <PageHeader title={t('TITLE_LOAD_DETAILS')} onCancel={handleHome} onClear={handleReset} />
        <LoadOutputDetails state={state} />
      </LoadReducer.Provider>
    )
  }

  return (
    <LoadReducer.Provider dispatch={dispatch}>
      <PageHeader title={t('TITLE_LOAD')} onCancel={handleCancel} onClear={handleReset} />
      {state.hasBlockingError.hasError ? (
        <BlockingMessage
          title={state.hasBlockingError.title}
          message={state.hasBlockingError.message}
          handleClose={handleBlockingErrorClick}
        ></BlockingMessage>
      ) : (
        <SectionList>
          <Section>
            <Card>
              <Form>
                <Flex.Item flex='auto'>
                  <Flex flexDirection='column' margin='1.5rem 0 0 0'>
                    <ClearFieldButton onClick={handleReset} />
                  </Flex>
                  <Flex flex='1 1 auto' flexDirection='column'>
                    <Field label={t('FIELD_OUTPUT_CARRIER_ID')}>
                      <BarcodeInput
                        api={outputTrackingId}
                        text={state.outputText}
                        autoFocus={state.outputAccepted === null}
                        disabled={state.outputAccepted !== null}
                        onChange={text => dispatch({ type: `change-output`, text })}
                        onSubmit={handleOutputAction}
                      />
                    </Field>
                    <Field label={t('FIELD_INPUT_TRACKING_ID')}>
                      <BarcodeInput
                        api={inputTrackingId}
                        text={state.inputText}
                        autoFocus={state.outputAccepted !== null}
                        disabled={!state.outputText || state.outputRejected !== null}
                        onChange={text => dispatch({ type: `change-input`, text })}
                        onSubmit={handleInputAction}
                      />
                    </Field>
                  </Flex>
                </Flex.Item>
              </Form>
            </Card>
          </Section>
          <CollapseWrapper isOpened={!!state.outputAccepted}>
            <Section>
              <Card header={<Counter count={state.inputPending.length}>{t('TITLE_VALIDATION')}</Counter>}>
                <List>
                  <ListItemPending text={t(`NOTIFICATION_SCAN_TRACKING_ID`)} />
                  {state.inputPending.map(x => (
                    <ListItemPending key={x.text} text={x.text} />
                  ))}
                </List>
              </Card>
            </Section>
            <Section>
              <LoadReceipt state={state} onReset={handleReset} />
            </Section>
          </CollapseWrapper>
          <LoadRouteHistory state={state} />
        </SectionList>
      )}
    </LoadReducer.Provider>
  )
}

interface IHistoryItem {
  text: string
  message: string
  status: StatusValue
  reasonArgs?: { [key: string]: string }
}

const LoadRouteHistory = ({ state }: { state: LoadState }) => {
  const { t } = useTranslation()

  const acceptedOutput = LoadSelectors.acceptedOutput(state)
  const rejectedOutput = LoadSelectors.rejectedOutput(state)

  // reverse so that last added item becomes the first in the list
  const acceptedInputs = LoadSelectors.acceptedInputs(state)
  const rejectedInputs = LoadSelectors.rejectedInputs(state)

  const accepted: IHistoryItem[] = acceptedInputs.map(x => {
    return {
      text: x.text,
      message: 'ACCEPTED',
      status: 'good',
    }
  })
  const rejected: IHistoryItem[] = rejectedInputs.map(x => {
    return {
      text: x.text,
      message: x.reason,
      status: 'bad',
      reasonArgs: x?.reasonArgs,
    }
  })

  const inputs = [...accepted.reverse(), ...rejected.reverse()]

  const count =
    (acceptedOutput !== null ? 1 : 0) +
    (rejectedOutput !== null ? 1 : 0) +
    acceptedInputs.length +
    rejectedInputs.length

  if (count === 0) {
    return <React.Fragment />
  }

  const showInputs = acceptedInputs.length > 0 || rejectedInputs.length > 0
  const showOutputs = acceptedOutput !== null || rejectedOutput !== null

  return (
    <Section>
      <Card
        header={
          <Counter countSuccess={acceptedInputs.length} countFail={rejectedInputs.length}>
            {t('TITLE_HISTORY')}
          </Counter>
        }
      >
        {showOutputs ? <ListItem primary={t('Output')} /> : null}
        {acceptedOutput !== null ? (
          <ListItemHistory text={acceptedOutput.text} message={t('ACCEPTED')} status='good' />
        ) : null}
        {rejectedOutput !== null ? (
          <ListItemHistory text={rejectedOutput.text} message={t(rejectedOutput.reason)} status='bad' />
        ) : null}

        {showInputs ? <ListItem primary={t('Input')} /> : null}
        {inputs.map(x => {
          return (
            <ListItemHistory
              key={`${x.status}-${x.text}-${x.message}`}
              text={x.text}
              message={t(x.message, x.reasonArgs)}
              status={x.status}
            />
          )
        })}
      </Card>
    </Section>
  )
}

export const LoadRouteCompleted = ({
  completed,
  onReset,
  outputText,
  carrierId,
}: {
  outputText: string
  completed: boolean
  onReset: () => void
  carrierId?: number | null
}) => {
  const [unlockCarrier] = useMutation(UNLOCK_CARRIER)

  const { t } = useTranslation()

  useEffect(() => {
    if (completed) {
      document.addEventListener('touchstart', onReset)
      document.addEventListener('keydown', onReset)

      if (carrierId) {
        unlockCarrier({
          variables: {
            carrierId: carrierId,
          },
        })
      }

      return () => {
        document.removeEventListener('keydown', onReset)
        document.removeEventListener('touchstart', onReset)
      }
    }
  }, [completed])

  if (!completed) return <React.Fragment />

  return (
    <CompletedDialogContainer>
      <CompletedDialogContent>
        <MText block textVariant='content bold'>
          {t('CARRIER_COMPLETED', { code: outputText })}
        </MText>
        <MText block textVariant='small' style={{ marginTop: '8px' }}>
          {t('TAP_TO_CONTINUE')}
        </MText>
        <CompletedDialogHR />
      </CompletedDialogContent>
    </CompletedDialogContainer>
  )
}

const ListItemPending = ({ text }: { text: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>{text}</Flex.Item>
        </Flex>
      }
    />
  )
}

const ListItemHistory = ({ status, text, message }: { status: StatusValue; text: string; message: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 1 auto'>
            <StatusText status={status}>{message}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}

const CompletedDialogContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 1em;
  background: rgba(0, 0, 0, 0.15);
`

const CompletedDialogContent = styled.div`
  position: absolute;
  background: white;
  width: calc(100% - 6rem);
  padding: 2rem;
  left: 1rem;
  top: 10%;
  text-align: center;
`

const CompletedDialogHR = styled.hr`
  color: #40b67f;
  background: #40b67f;
  height: 1.5px;
`
