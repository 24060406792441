import { useTranslation } from 'react-i18next'
import { useAppSettingsContext, availableLanguages } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { useHistory } from '../../shared/use-history'
import { MColor } from '@mprise/react-ui'
import type { AppLanguage } from '../../context/AppSettingsContext'

export const SettingsLanguageRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { language, setLanguage } = useAppSettingsContext()

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSelection = (x: AppLanguage) => {
    setLanguage(x)
    h.push('/settings')
  }
  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_LANGUAGE')}>
            <List>
              {availableLanguages
                .map(x => ({ id: x, name: t(`LANGUAGE_${x.toUpperCase()}`) }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value='language' style={{ fontSize: '2rem' }} />}
                    primary={x.name}
                    action={
                      language.id === x.id ? <MaterialIcon style={{ color: MColor.primary }} value='check' /> : null
                    }
                    onClick={() => handleSelection(x)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
