import React, { useContext, useEffect } from 'react'
import { usePersistentState } from '../shared/persistant-state'
import { i18n } from '../i18n/instance'
import { useTranslation } from 'react-i18next'
import { MpriseAuthProvider, MpriseUser } from '@mprise/react-auth'
import { ErrorBoundary } from '../error-boundary'
import { fail } from '../shared/typescript'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCE } from '../gql/resources'

export interface AppLanguage {
  id?: string
  name?: string
}
export enum ScanningSetting {
  CAMERA = 'CAMERA',
  SCANNER = 'SCANNER',
  KEYBOARD = 'KEYBOARD',
}
export type AgriwareCompanySubset = {
  id: number
  name: string
}
export type AgriwarePositionSubset = {
  id: string
  code: string
  name: string
}
export type DefaultActivateStatusValue = {
  id: string
  name: string
}
export type DefaultReceiveStatusValue = {
  id: string
  name: string
}
export type DefaultTransferStatusValue = {
  id: string
  name: string
}
export type ResourceSubset = {
  id: string
  code: string
  name: string
}

export const availableLanguages = [`en`, `es`, `nl`]

export const AppSettingsContext = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = usePersistentState<AppLanguage>(`__LANGUAGE`, {})
  const [scanningSetting, setScanningSetting] = usePersistentState<ScanningSetting>('SCANNING', ScanningSetting.CAMERA)
  const [currentMode, setCurrentMode] = usePersistentState<boolean>(`__BETA_MODE`, false)
  const [currentCompany, setCurrentCompany] = usePersistentState<AgriwareCompanySubset | null>(
    `__DEFAULT_COMPANY`,
    null,
  )
  const [defaultPosition, setDefaultPosition] = usePersistentState<AgriwarePositionSubset | null>(
    `__DEFAULT_POSITION`,
    null,
  )
  const [defaultActivateStatus, setDefaultActivateStatus] = usePersistentState<DefaultActivateStatusValue | null>(
    `__DEFAULT_ACTIVATE_STATUS`,
    null,
  )
  const [defaultReceiveStatus, setDefaultReceiveStatus] = usePersistentState<DefaultReceiveStatusValue | null>(
    `__DEFAULT_RECEIVE_STATUS`,
    null,
  )
  const [defaultTransferStatus, setDefaultTransferStatus] = usePersistentState<DefaultTransferStatusValue | null>(
    `__DEFAULT_STATUS`,
    null,
  )
  const [enableScanResourceInTransfer, setEnableScanResourceInTransfer] = usePersistentState<boolean>(
    `__SCAN_RESOURCE_IN_TRANSFER`,
    false,
  )
  const [defaultResource, setDefaultResource] = usePersistentState<ResourceSubset | null>(`__DEFAULT_RESOURCE`, null)

  const { t } = useTranslation()

  const { user } = MpriseAuthProvider.useContext()
  const allowedRoles = [`IDENTITYSERVER_USER_ADMIN`, `IDENTITYSERVER_TENANT_ADMIN`, `IDENTITYSERVER_ROLE_ADMIN`]
  const hasAdminRole = user?.roles.some(role => allowedRoles.includes(role))

  const [getResource] = useLazyQuery(GET_RESOURCE)

  useEffect(() => {
    ;(async () => {
      const subjectId = user ? (user as MpriseUser & { sub: string }).sub : null

      if (subjectId) {
        const { data } = await getResource({
          variables: {
            filter: {
              externalUserId: subjectId,
            },
          },
        })

        const resource = data?.resource
        if (!hasAdminRole && !resource) {
          fail(t(`NO_RESOURCE_FOUND`, { x: user?.email }))
        }

        setDefaultResource(
          resource
            ? {
                code: resource?.code ?? ``,
                id: resource?.id ?? ``,
                name: resource?.name ?? ``,
              }
            : null,
        )
      }
    })()
  }, [user])

  useEffect(() => {
    const lang = language.id ?? `en`
    i18n.changeLanguage(lang)
  }, [language])

  return (
    <ErrorBoundary handleUnrejectedPromise={true}>
      <AppSettingsContext.Context.Provider
        value={{
          language,
          setLanguage,
          scanningSetting,
          setScanningSetting,
          currentMode,
          setCurrentMode,
          currentCompany,
          setCurrentCompany,
          defaultPosition,
          setDefaultPosition,
          defaultActivateStatus,
          setDefaultActivateStatus,
          defaultReceiveStatus,
          setDefaultReceiveStatus,
          defaultTransferStatus,
          setDefaultTransferStatus,
          enableScanResourceInTransfer,
          setEnableScanResourceInTransfer,
          isAdmin: !!hasAdminRole,
          defaultResource,
          setDefaultResource,
        }}
      >
        {children}
      </AppSettingsContext.Context.Provider>
    </ErrorBoundary>
  )
}
AppSettingsContext.Context = React.createContext<{
  language: AppLanguage
  setLanguage: (s: AppLanguage) => void
  scanningSetting: ScanningSetting
  setScanningSetting: (s: ScanningSetting) => void
  currentMode: boolean
  setCurrentMode: (s: boolean) => void
  currentCompany: AgriwareCompanySubset | null
  setCurrentCompany: (s: AgriwareCompanySubset | null) => void
  defaultPosition: AgriwarePositionSubset | null
  setDefaultPosition: (s: AgriwarePositionSubset | null) => void
  defaultActivateStatus: DefaultActivateStatusValue | null
  setDefaultActivateStatus: (s: DefaultActivateStatusValue | null) => void
  defaultReceiveStatus: DefaultReceiveStatusValue | null
  setDefaultReceiveStatus: (s: DefaultReceiveStatusValue | null) => void
  defaultTransferStatus: DefaultTransferStatusValue | null
  setDefaultTransferStatus: (s: DefaultTransferStatusValue | null) => void
  enableScanResourceInTransfer: boolean
  setEnableScanResourceInTransfer: (s: boolean) => void
  isAdmin: boolean
  defaultResource: ResourceSubset | null
  setDefaultResource: (s: ResourceSubset | null) => void
}>({
  language: {},
  setLanguage: () => {
    throw Error(`AppSettingsContext missing`)
  },
  scanningSetting: ScanningSetting.CAMERA,
  setScanningSetting: () => {
    throw Error(`ScanningContainer missing`)
  },
  currentMode: false,
  setCurrentMode: () => {
    throw Error(`BetaModeContainer missing`)
  },
  currentCompany: {} as any /* TODO: make position properties optional */,
  setCurrentCompany: () => {
    throw Error(`CurrentCompanyContainer missing`)
  },
  defaultPosition: {} as any /* TODO: make position properties optional */,
  setDefaultPosition: () => {
    throw Error(`DefaultPositionContainer missing`)
  },
  defaultActivateStatus: null,
  setDefaultActivateStatus: () => {
    throw Error(`DefaultActivateStatusContainer missing`)
  },
  defaultReceiveStatus: null,
  setDefaultReceiveStatus: () => {
    throw Error(`DefaultReceiveStatusContainer missing`)
  },
  defaultTransferStatus: null,
  setDefaultTransferStatus: () => {
    throw Error(`DefaultTransferStatusContainer missing`)
  },
  enableScanResourceInTransfer: false,
  setEnableScanResourceInTransfer: () => {
    throw Error(`ScanResourceInTransferContainer missing`)
  },
  isAdmin: false,
  defaultResource: {} as any /* TODO: make position properties optional */,
  setDefaultResource: () => {
    throw Error(`DefaultResourceContainer missing`)
  },
})

export const useAppSettingsContext = () => {
  const context = useContext(AppSettingsContext.Context)
  if (!context) {
    throw new Error('Context called without a Provider.')
  }
  return context
}
