import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { Section, SectionList } from '../../mprise-light/section'
import { SearchInput } from '../../mprise-light/search-input'
import { useDebounceValue } from '../../shared/debounce'
import { useHistory } from '../../shared/use-history'
import { useLazyQuery } from '@apollo/client'
import { GET_POSITIONS } from '../../gql/positions'
import { SettingsItemsList } from './settings-items-list'
import type { AgriwarePositionSubset } from '../../context/AppSettingsContext'

export const SettingsPositionRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const { defaultPosition, currentCompany, setDefaultPosition } = useAppSettingsContext()

  const [getPositions, { data, loading, error }] = useLazyQuery(GET_POSITIONS, {
    onError: console.error,
  })

  useEffect(() => {
    if (currentCompany?.id) {
      getPositions({
        variables: {
          filter: {
            companyId: +currentCompany.id,
            ...(debouncedSearch && { searchString: debouncedSearch }),
          },
          limit: 50,
          offset: 0,
        },
      })
    }
  }, [currentCompany, debouncedSearch, getPositions])

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSelected = (selectedItem: AgriwarePositionSubset | null) => {
    setDefaultPosition(selectedItem)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_CURRENT_POSITION')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <SettingsItemsList
              loading={loading}
              items={data?.positions?.page}
              error={error}
              selected={defaultPosition}
              setSelected={handleSelected}
              iconName='place'
              getDisplayName={(x: any) => x.code ?? x.name}
            />
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
