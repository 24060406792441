import { useTranslation } from 'react-i18next'
import { useAppSettingsContext, ScanningSetting } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { MaterialIcon } from '../../mprise-light/icon'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { useHistory } from '../../shared/use-history'
import { MColor } from '@mprise/react-ui'

export const SettingsScanningRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { scanningSetting, setScanningSetting } = useAppSettingsContext()

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSelection = (setting: ScanningSetting) => {
    setScanningSetting(setting)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('CHOOSE_SCANNING_METHOD')}>
            <List>
              {Object.values(ScanningSetting).map(setting => (
                <ListItem
                  key={setting}
                  icon={<MaterialIcon value={getScanningIconByScanningSetting(setting)} style={{ fontSize: '2rem' }} />}
                  primary={t(`SCANNING_${setting}`)}
                  action={
                    scanningSetting === setting ? (
                      <MaterialIcon style={{ color: MColor.primary }} value='check' />
                    ) : null
                  }
                  onClick={() => handleSelection(setting)}
                />
              ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}

const getScanningIconByScanningSetting = (setting: ScanningSetting): string => {
  switch (setting) {
    case ScanningSetting.CAMERA:
      return 'photo_camera'
    case ScanningSetting.KEYBOARD:
      return 'keyboard'
    case ScanningSetting.SCANNER:
    default:
      return 'qr_code_scanner'
  }
}
