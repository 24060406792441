import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ResourceSubset } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { SearchInput } from '../../mprise-light/search-input'
import { Section, SectionList } from '../../mprise-light/section'
import { useDebounceValue } from '../../shared/debounce'
import { useHistory } from '../../shared/use-history'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCES } from '../../gql/resources'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { SettingsItemsList } from './settings-items-list'

export const SettingsResourceRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const { currentCompany, defaultResource, setDefaultResource } = useAppSettingsContext()

  const [getResources, { data, loading, error }] = useLazyQuery(GET_RESOURCES, {
    onError: console.error,
  })

  useEffect(() => {
    if (currentCompany?.id) {
      getResources({
        variables: {
          filter: {
            companyId: +currentCompany.id,
            ...(debouncedSearch && { searchString: debouncedSearch }),
            removed: false,
          },
          limit: 50,
          offset: 0,
        },
      })
    }
  }, [currentCompany, debouncedSearch, getResources])

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSelected = (selectedItem: ResourceSubset | null) => {
    setDefaultResource(selectedItem)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_CURRENT_RESOURCE')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <SettingsItemsList
              loading={loading}
              items={data?.resources?.page}
              error={error}
              selected={defaultResource}
              setSelected={handleSelected}
              iconName='person'
            />
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
