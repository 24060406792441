import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { PageHeader } from '../../mprise-light/header'
import { Section, SectionList } from '../../mprise-light/section'
import { useHistory } from '../../shared/use-history'
import { useLazyQuery } from '@apollo/client'
import { GET_STATUSES } from '../../gql/statuses'
import { SettingsItemsList } from './settings-items-list'
import type { DefaultReceiveStatusValue } from '../../context/AppSettingsContext'

export const SettingDefaultReceiveStatus = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { defaultReceiveStatus, currentCompany, setDefaultReceiveStatus } = useAppSettingsContext()

  const [getStatuses, { data, loading, error }] = useLazyQuery(GET_STATUSES, {
    onError: console.error,
  })

  useEffect(() => {
    if (currentCompany?.id) {
      getStatuses({
        variables: {
          companyId: +currentCompany.id,
          statuses: ['IN_PROCESSING', 'STORED'],
        },
      })
    }
  }, [currentCompany, getStatuses])

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSelected = (selectedItem: DefaultReceiveStatusValue | null) => {
    setDefaultReceiveStatus(selectedItem)
    h.push('/settings')
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_DEFAULT_RECIEVE_STATUS')}>
            <SettingsItemsList
              loading={loading}
              items={data?.customStatuses}
              error={error}
              selected={defaultReceiveStatus}
              setSelected={handleSelected}
              iconName='schedule'
            />
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
