import { MAudio } from '@mprise/react-ui'
import { CSSProperties, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { useHistory } from '../../shared/use-history'
import { PickReducer, PickSelectors, PickState, PickTrackingId } from './reducer'
import { useMutation } from '@apollo/client'
import { PICK } from '../../gql/pick'
import { parseError } from '../../shared/errors'
import { FixedSizeList as VList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ClearFieldButton } from '../../shared/clear-field-button'

export const PickRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [state, dispatch] = PickReducer.useReducer()

  const handleCancel = () => {
    dispatch({ type: 'reset' })
    h.push('/')
  }

  const handleReset = () => {
    dispatch({ type: 'reset' })
  }

  const pendingOrFailed = PickSelectors.pendingOrFailedValidation(state)
  const completed = PickSelectors.completedValidation(state)

  return (
    <PickReducer.Provider dispatch={dispatch}>
      <div className='box'>
        <PageHeader title={t('TITLE_PICK')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <PickFormCard state={state} />
        </Section>
        <CollapseWrapper isOpened={pendingOrFailed.length > 0}>
          <Section>
            <ValidationCard state={state} />
          </Section>
        </CollapseWrapper>
        {completed.length > 0 && (
          <Section style={{ flex: '1 1 auto' }}>
            <HistoryCard state={state} />
          </Section>
        )}
      </div>
    </PickReducer.Provider>
  )
}

const PickFormCard = ({ state }: { state: PickState }) => {
  const { t } = useTranslation()
  const dispatch = PickReducer.useDispatch()

  const { currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id

  const inputPosition = useBarcodeInput('inputPosition', 'inputResource')
  const inputResource = useBarcodeInput('inputResource', 'inputTrackingId')
  const inputTrackingId = useBarcodeInput('inputTrackingId')

  const alerts = FlashAlerts.useAlert()

  const [submitPick] = useMutation(PICK)

  const handleTrackingIdScan = (pickTrackingId: PickTrackingId) => {
    const { positionText, resourceText, trackingIdText } = pickTrackingId
    dispatch({
      type: 'admit-trackingId',
      positionText,
      resourceText,
      trackingIdText,
    })

    submitPick({
      variables: {
        input: {
          companyId: +companyId!,
          trackingIdCode: pickTrackingId.trackingIdText,
          positionCode: pickTrackingId.positionText,
          resourceCode: pickTrackingId.resourceText,
        },
      },
    })
      .then(() => {
        dispatch({
          type: 'accept-trackingId',
          key: `${state.positionText};${state.resourceText};${state.trackingIdText}`,
          status: 'success',
          message: null,
          resource: state.resourceText,
          trackingId: state.trackingIdText,
        })
        alerts.push(t('NOTIFICATION_ACCEPTED'), 'success')
        MAudio.scanSuccess()
      })
      .catch(e => {
        const { errorMessage, messageArgs } = parseError(e)

        alerts.push(t(errorMessage, messageArgs), 'error')
        MAudio.scanError()

        dispatch({
          type: 'accept-trackingId',
          key: pickTrackingId.key,
          status: 'error',
          message: errorMessage,
          messageArgs: messageArgs,
          resource: pickTrackingId.resourceText,
          trackingId: pickTrackingId.trackingId,
        })
      })
  }

  useEffect(() => {
    if (state.positionText && state.resourceText) {
      inputTrackingId.focus()
    } else if (state.positionText) {
      inputResource.focus()
    }
  }, [])

  return (
    <Card>
      <Form>
        <Flex.Item flex='auto'>
          <Flex flexDirection='column' margin='1.5rem 0 0 0'>
            <ClearFieldButton
              onClick={() => {
                dispatch({ type: 'reset-form' })
                dispatch({ type: 'input-position-readOnly', readonly: false })

                inputPosition.focus()
              }}
            />
          </Flex>
          <Flex flex='1 1 auto' flexDirection='column'>
            <Field label={t('FIELD_POSITION')} required>
              <BarcodeInput
                api={inputPosition}
                text={state.positionText}
                disabled={state.positionTextReadOnly}
                autoFocus
                onChange={text => {
                  dispatch({ type: 'input-position', text })
                }}
                onSubmit={text => {
                  if (text) {
                    dispatch({ type: 'input-position-readOnly', readonly: true })
                    inputPosition.next()
                  }
                }}
              />
            </Field>
            <Field label={t('FIELD_RESOURCE')} required>
              <BarcodeInput
                api={inputResource}
                text={state.resourceText}
                onChange={text => {
                  dispatch({ type: 'input-resource', text })
                }}
                onSubmit={text => {
                  if (text) {
                    inputResource.next()
                  }
                }}
              />
            </Field>
            <Field label={t('FIELD_TRACKING_ID')} required>
              <BarcodeInput
                api={inputTrackingId}
                text={state.trackingIdText}
                disabled={!(state.positionText && state.resourceText)}
                onChange={text => {
                  dispatch({ type: 'input-trackingId', text })
                }}
                onSubmit={text => {
                  const positionText = state.positionText
                  const resourceText = state.resourceText
                  if (!positionText) {
                    MAudio.scanError()
                    alerts.push(t('NOTIFICATION_REQUIRES_POSITION'), 'error')
                    inputPosition.focus()
                  } else if (!resourceText) {
                    MAudio.scanError()
                    alerts.push(t('NOTIFICATION_REQUIRES_RESOURCE'), 'error')
                    inputResource.focus()
                  } else {
                    if (text) {
                      const trackingIdText = text
                      const isKnown = PickSelectors.isKnownAndNotAnError(state, {
                        positionText,
                        resourceText,
                        trackingIdText,
                      })
                      if (isKnown) {
                        MAudio.scanError()
                        alerts.push(t('NOTIFICATION_ALREADY_SCANNED'), 'error')
                        inputTrackingId.focus()
                      } else {
                        inputResource.focus()
                        handleTrackingIdScan({
                          key: `${positionText};${resourceText};${trackingIdText}`,
                          message: null,
                          positionText,
                          resource: null,
                          resourceText,
                          status: 'waiting',
                          trackingId: null,
                          trackingIdText,
                        })
                      }
                    }
                  }
                }}
              />
            </Field>
          </Flex>
        </Flex.Item>
      </Form>
    </Card>
  )
}

const ValidationCard = ({ state }: { state: PickState }) => {
  const { t } = useTranslation()
  const pending = PickSelectors.pendingValidation(state)

  return (
    <Card header={<Counter count={pending.length}>{t('TITLE_VALIDATION')}</Counter>}>
      <List>
        {pending.length > 0 ? (
          pending.map(x => (
            <PickListItem
              key={x.key}
              status='neutral'
              text={x.trackingIdText}
              message={t('ACTIVATE_PROGRESS_VALIDATING')}
            />
          ))
        ) : (
          <ListItem primary={t('NOTIFICATION_SCAN_TRACKING_ID')} />
        )}
      </List>
    </Card>
  )
}

const HistoryCard = ({ state }: { state: PickState }) => {
  const { t } = useTranslation()

  const totalItems = PickSelectors.completedValidation(state)
  const historyItems = PickSelectors.completedValidation(state).slice(-50).reverse()

  const count = totalItems.reduce(
    (acc, item) => {
      if (item.status === 'error') {
        acc.fail++
      } else if (item.status === 'success') {
        acc.success++
      }
      return acc
    },
    { success: 0, fail: 0 },
  )

  return (
    <Card
      style={{ height: '95%' }}
      header={
        <Counter countSuccess={count.success} countFail={count.fail}>
          {t('TITLE_HISTORY')}
        </Counter>
      }
    >
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <VList
              itemData={historyItems}
              innerElementType='ul'
              itemCount={historyItems.length || 0}
              itemSize={55}
              height={height}
              width={width}
              className='ml-list'
            >
              {({ data: x, index: i, style }) =>
                x[i]!.status === 'success' ? (
                  <PickListItem
                    style={style}
                    key={x[i]!.key}
                    status='good'
                    text={x[i]!.trackingIdText ?? '...'}
                    message={x[i]!.resourceText ?? '...'}
                  />
                ) : (
                  <PickListItem
                    style={style}
                    key={x[i]!.key}
                    status='bad'
                    text={x[i]!.trackingIdText}
                    message={t(x[i]!.message ?? x[i]!.status, x[i]!.messageArgs)}
                  />
                )
              }
            </VList>
          )
        }}
      </AutoSizer>
    </Card>
  )
}

const PickListItem = ({
  text,
  message,
  status,
  style,
}: {
  text: string
  message: string
  status: StatusValue
  style?: CSSProperties
}) => {
  return (
    <ListItem
      style={style}
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText status={status}>{message}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}
